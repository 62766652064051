/*
 |--------------------------------------------------------------------------
 | File Name : attributes.service
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for attributes API services handler
 |
 */
import ApiServices from '@/libs/services/api-services'

class AttributesService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * Attributes list API service
   * @param formData
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('attribute', { params: params })
  }

  /*
   * Attribute details API service
   * @param formData
   * @returns {*}
   */
  details = (id) => {
    return this.request.get(`attribute/${id}`)
  }
  /*
   * Attributes create API service
   * @returns {*}
   */
  create = (formData, params = {}) => {
    return this.request.post('attribute', formData, { params })
  }
  /*
   * Attribute edit API service
   * @returns {*}
   */
  update = (formData, id) => {
    return this.request.patch(`attribute/${id}`, formData)
  }
  /*
   * Attribute delete API service
   * @returns {*}
   */
  deleteItem = (formData) => {
    return this.request.delete(`attribute/${formData.id}`)
  }
}

export default new AttributesService()
