import {
  messaging,
  onMessage,
  getToken,
  cloudMessagingKey,
} from '@/libs/helper/firebase'
import store from '@/store'
import { setLocal } from '@/libs/helper/local'

onMessage(messaging, () => {
  store.commit('app/setNotify', true)
})

Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    console.log('Notification enabled')
  }
})

getToken(messaging, { vapidKey: cloudMessagingKey })
  .then((token) => {
    if (token) {
      store.commit('app/setFcmToken', token)
      setLocal('fcm', token)
      return token
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      )
    }
  })
  .catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
  })

export { getToken }
