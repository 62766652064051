/*
 |--------------------------------------------------------------------------
 | File Name : auth.service
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for auth services handler
 |
 */
import ApiServices from '@/libs/services/api-services'

class AuthService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * Login API service
   * @param formData
   * @returns {*}
   */
  login = (formData) => {
    return this.request.post('login', formData)
  }
  /*
   * Logout API service
   * @returns {*}
   */
  logout = (formData) => {
    return this.request.post('logout', formData)
  }
  /*
   * Forgot password API service
   * @param formData
   * @returns {*}
   */
  forgotPassword = (formData) => {
    return this.request.post('forgot-password', formData)
  }
  /*
   * Reset password API service
   * @param formData
   * @returns {*}
   */
  resetPassword = (formData, url) => {
    return this.request.post(url, formData)
  }
  /*
   * Reset password for auth user API service
   * @param formData
   * @returns {*}
   */
  authUserResetPassword = (formData) => {
    return this.request.post('password-reset', formData)
  }
  /*
   * Profile details API service
   * @param id
   * @returns {*}
   */
  profile = () => {
    return this.request.get(`profile`)
  }
  /*
   * Update profile details API service
   * @param id
   * @param formData
   * @returns {*}
   */
  updateProfile = (formData) => {
    return this.request.patch(`profile`, formData)
  }
}

export default new AuthService()
