/*
 * @File actions.js
 * @Description This File is for app common state actions
 */

import Helpers from '@/libs/helper/functions'
import FileUploaderService from '@/libs/services/modules/file-uploader.service'
import AppService from '@/libs/services/modules/app.service'
import { NOTIFICATION_TYPES } from '@/libs/helper/constants'

const actions = {
  async fileUpload(context, fileArray) {
    const res = await FileUploaderService.uploadFiles(fileArray)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  async getNotifications(context, payload) {
    const result = await AppService.getNotifications(payload)
    return Helpers.checkResponse(result, false)
      .then((response) => {
        const data = response.data.data.map((item) => {
          const user = item.notification?.registered
          return {
            id: item.id,
            typeId: item.notification?.registered?.id ?? '',
            userId: item.notification?.registered?.user_id ?? '',
            title:
              item.type === NOTIFICATION_TYPES.VENDOR_SIGNUP
                ? `${user.name} Registered as Vendor`
                : item.type === NOTIFICATION_TYPES.FOODIE_SIGNUP
                ? `${user.name} Registered as Foodie`
                : `New Notification`,
            user: user,
            type: item.type,
            notificationTime: Helpers.formatDate(
              item.created_at,
              'MMM DD, YYYY HH:mm',
            ),
          }
        })
        context.commit('setNotifications', data)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        return Promise.reject(err.response.data)
      })
  },
}

export default actions
