/*
 * @File vendors.service.js
 * @Description This File is for foodies API services
 */
import ApiServices from '@/libs/services/api-services'

class FoodiesService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List foodies API service
   * @param params
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('foodie', { params })
  }
  /*
   * Foodies details API service
   * @param id
   * @returns {*}
   */
  show = (id) => {
    return this.request.get(`foodie/${id}`)
  }
  /*
   * Update foodies details API service
   * @param id
   * @param formData
   * @returns {*}
   */
  update = (id, formData) => {
    return this.request.patch(`foodie/${id}`, formData)
  }
  /*
   * Delete foodies API service
   * @param id
   * @returns {*}
   */
  destroy = (id) => {
    return this.request.delete(`foodie/${id}`)
  }
  /*
   * Foodies Likes List API service
   * @param params
   * @returns {*}
   */
  likes = (params) => {
    return this.request.get('likes', { params })
  }
}

export default new FoodiesService()
