<template>
  <CModal backdrop="static" :visible="data.visible" @close="closeHandler">
    <CModalHeader>
      <CModalTitle>Delete Item</CModalTitle>
    </CModalHeader>
    <CModalBody>Do you really want to delete this item ?</CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="closeHandler"> Cancel</CButton>
      <CButton color="danger" @click="confirmationHandler">
        <span class="text-white"> Delete </span>
      </CButton>
    </CModalFooter>
  </CModal>
</template>
<script>
export default {
  name: 'AppDeleteConfirmation',
}
</script>
<script setup>
import { onMounted, reactive } from 'vue'
import EventBus from '@/libs/event-bus'

const data = reactive({
  visible: false,
  confirmationEvent: 'delete-confirmation',
  params: {},
})

/*
 * Close handler
 */
function closeHandler() {
  data.visible = false
}

/*
 * Confirmation handler
 */
function confirmationHandler() {
  EventBus.$emit(`${data.confirmationEvent}`, data.params)
}

onMounted(() => {
  EventBus.$off('delete-alert-on')
  EventBus.$on('delete-alert-on', (payload) => {
    data.visible = true
    if (payload.confirmationEvent) {
      data.confirmationEvent = payload.confirmationEvent
    }
    if (payload.params) {
      data.params = payload.params
    }
  })
  EventBus.$off('delete-alert-off')
  EventBus.$on('delete-alert-off', (payload) => {
    data.visible = false
    data.confirmationEvent = 'delete-confirmation'
    data.params = {}
  })
})
</script>
<style scoped lang="scss"></style>
