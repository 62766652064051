/*
 * @File actions.js
 * @Description This File is for attributes state actions
 */
import Helpers from '@/libs/helper/functions'
import AttributesService from '@/libs/services/modules/attributes.service'

const actions = {
  /*
   * Attributes list store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    const res = await AttributesService.list({
      ...payload?.params,
      ...context.getters['getFilterWithSortParams'],
    })
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setAttributesList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Attribute details store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async details(context, payload) {
    const res = await AttributesService.details(payload.id)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setAttributeDetails', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Attributes create store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async create(context, payload) {
    const res = await AttributesService.create(
      payload.formData,
      payload.params || {},
    )
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Attribute delete store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async deleteItem(context, payload) {
    const res = await AttributesService.deleteItem(payload.formData)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Attribute update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    const res = await AttributesService.update(payload.formData, payload.id)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default actions
