import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
/*
 * Attributes routes components
 * @returns {Promise<{}>}
 * @constructor
 */
const AttributesList = () => import('@/views/pages/attributes')
const AttributesForm = () => import('@/views/pages/attributes/Form')
const AttributeDetails = () => import('@/views/pages/attributes/Details')

/*
 * Vendors routes components
 * @returns {Promise<{}>}
 * @constructor
 */
const VendorsList = () => import('@/views/pages/vendors')
const VendorsDetails = () => import('@/views/pages/vendors/Details')
const VendorsForm = () => import('@/views/pages/vendors/Form')
const VendorBusinessDetails = () =>
  import('@/components/vendors/BusinessDetails')
const VendorBusinessHours = () => import('@/components/vendors/BusinessHours')
const VendorGeneralInfo = () => import('@/components/vendors/GeneralInfo')
const VendorMenuList = () => import('@/components/vendors/menu/List')
const VendorMenuForm = () => import('@/components/vendors/menu/Form')
const VendorGallery = () => import('@/components/vendors/gallery/List')
const VendorPerformance = () => import('@/components/vendors/Performance')
const VendorPosts = () => import('@/components/vendors/PostsList.vue')

/*
 * Foodies routes components
 * @returns {Promise<{}>}
 * @constructor
 */
const FoodiesList = () => import('@/views/pages/foodies')
const FoodiesDetails = () => import('@/views/pages/foodies/Details')
const FoodieLikedEateries = () => import('@/components/foodies/LikedEateries')
const FoodieLikedDishes = () => import('@/components/foodies/LikedDishes')
const FoodieReviews = () => import('@/components/foodies/FoodieReviews')

/*
 * Menu category related route(s)
 * @returns {Promise<{}>}
 * @constructor
 */
const MenuCategoriesList = () => import('@/views/pages/menu-categories')
const MenuCategoryForm = () => import('@/views/pages/menu-categories/Form.vue')

/*
 * Profile related route(s)
 * @returns {Promise<{}>}
 * @constructor
 */
const Profile = () => import('@/views/pages/profile')
const ProfileForm = () => import('@/views/pages/profile/Form.vue')
const AuthUserResetPasswordFrom = () =>
  import('@/views/pages/profile/PasswordResetForm.vue')

/*
 * Masters related routes
 * @returns {Promise<{}>}
 * @constructor
 */
const MastersList = () => import('@/views/pages/masters')
const MastersForm = () => import('@/views/pages/masters/Form')

/*
 * Posts related routes
 * @returns {Promise<*>}
 * @constructor
 */
const PostsList = () => import('@/views/pages/posts')
const PostForm = () => import('@/views/pages/posts/Form.vue')
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/vendors',
        name: 'Vendors',
        redirect: () => {
          return { name: 'Vendors List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Vendors List',
            component: VendorsList,
          },
          {
            path: ':id',
            name: 'Vendor Details',
            component: VendorsDetails,
            redirect: () => {
              return { name: 'Vendor Business Details' }
            },
            children: [
              {
                path: '',
                name: 'Vendor Business Details',
                component: VendorBusinessDetails,
              },
              {
                path: 'business-hours',
                name: 'Vendor Business Hours',
                component: VendorBusinessHours,
              },
              {
                path: 'general-info',
                name: 'Vendor General Info',
                component: VendorGeneralInfo,
              },
              {
                path: 'performance',
                name: 'Vendor Performance',
                component: VendorPerformance,
              },
              {
                path: 'menu',
                name: 'Vendor Menu',
                render() {
                  return h(resolveComponent('AppRouterView'))
                },
                redirect: () => {
                  return { name: 'Vendor Menu List' }
                },
                children: [
                  {
                    path: '',
                    name: 'Vendor Menu List',
                    meta: { parent: 'Vendor Menu' },
                    component: VendorMenuList,
                  },
                  {
                    path: 'create',
                    name: 'Vendor Menu Add',
                    meta: { parent: 'Vendor Menu' },
                    component: VendorMenuForm,
                  },
                  {
                    path: ':menuId/edit',
                    name: 'Vendor Menu Edit',
                    meta: { parent: 'Vendor Menu' },
                    component: VendorMenuForm,
                  },
                  {
                    path: ':menuId/performance',
                    name: 'Vendor Menu Performance',
                    meta: { parent: 'Vendor Menu' },
                    component: VendorPerformance,
                  },
                ],
              },
              {
                path: 'gallery',
                name: 'Vendor Gallery',
                component: VendorGallery,
              },
              {
                path: 'posts',
                name: 'Vendor Posts',
                component: VendorPosts,
              },
            ],
          },
          {
            path: 'create',
            name: 'Vendors Form',
            component: VendorsForm,
          },
          {
            path: ':id/edit',
            name: 'Update Vendor Details',
            component: VendorsForm,
          },
        ],
      },
      {
        path: '/foodies',
        name: 'Foodies',
        redirect: () => {
          return { name: 'Foodies List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Foodies List',
            component: FoodiesList,
          },
          {
            path: ':id',
            name: 'Foodie Details',
            component: FoodiesDetails,
            redirect: () => {
              return { name: 'Foodie Liked Eateries' }
            },
            children: [
              {
                path: '',
                name: 'Foodie Liked Eateries',
                component: FoodieLikedEateries,
              },
              {
                path: 'liked-dishes',
                name: 'Foodie Liked Dishes',
                component: FoodieLikedDishes,
              },
              {
                path: 'reviews',
                name: 'Foodie Reviews',
                component: FoodieReviews,
              },
            ],
          },
        ],
      },
      {
        path: '/attributes',
        name: 'Attributes',
        redirect: () => {
          return { name: 'Attributes List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Attributes List',
            component: AttributesList,
          },
          {
            path: 'create',
            name: 'Attributes Form',
            component: AttributesForm,
          },
          {
            path: ':id',
            name: 'Attribute Details',
            component: AttributeDetails,
          },
          {
            path: ':id/create',
            name: 'Create Attribute Value',
            component: AttributesForm,
          },
          {
            path: ':id/edit',
            name: 'Attribute Update',
            component: AttributesForm,
          },
        ],
      },
      {
        path: '/menu-categories',
        name: 'Menu Categories',
        redirect: () => {
          return { name: 'Menu Categories List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Menu Categories List',
            component: MenuCategoriesList,
          },
          {
            path: 'create',
            name: 'Menu Category Form',
            component: MenuCategoryForm,
          },
          {
            path: ':id/edit',
            name: 'Menu Category Update',
            component: MenuCategoryForm,
          },
        ],
      },
      {
        path: '/masters',
        name: 'Masters',
        redirect: () => {
          return { name: 'Masters List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Masters List',
            component: MastersList,
          },
          {
            path: ':key/edit',
            name: 'Update Master',
            component: MastersForm,
          },
        ],
      },
      {
        path: '/posts',
        name: 'Posts',
        redirect: () => {
          return { name: 'Posts List' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Posts List',
            component: PostsList,
          },
          {
            path: 'create',
            name: 'Create Post',
            component: PostForm,
          },
          {
            path: ':id/edit',
            name: 'Update Post Details',
            component: PostForm,
          },
        ],
      },
      {
        path: '/profile',
        name: 'Profile',
        redirect: () => {
          return { name: 'Profile Details' }
        },
        component: {
          render() {
            return h(resolveComponent('AppRouterView'))
          },
        },
        children: [
          {
            path: '',
            name: 'Profile Details',
            component: Profile,
          },
          {
            path: 'edit',
            name: 'Update Profile',
            component: ProfileForm,
          },
          {
            path: 'reset-password',
            name: 'Update Password',
            component: AuthUserResetPasswordFrom,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: {
      render() {
        return h(resolveComponent('AppRouterView'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/auth/Login.vue'),
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: () => import('@/views/pages/auth/ForgotPassword.vue'),
      },
      {
        path: 'reset-password',
        name: 'Reset Password',
        component: () => import('@/views/pages/auth/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/pages/error/Page404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
