/*
 |--------------------------------------------------------------------------
 | File Name : api-services
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for global API service
 |
 */
import axios from 'axios'
import ApiInterceptor from './api-interceptor'

class ApiService {
  constructor() {
    this.service = axios.create()
    this.interceptor = new ApiInterceptor(this.service)
    this.service.interceptors.request.use(this.interceptor.setRequestConfig)
    this.service.interceptors.response.use(
      this.interceptor.handleSuccessResponse,
      this.interceptor.handleErrorResponse,
    )
    this.baseUrl = process.env.VUE_APP_API_URL
  }

  /*
   * Global api request service
   * @param method
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  request = (method, url, data = {}, config = {}) => {
    return this.service({
      method,
      url:
        url.includes('http://') || url.includes('https://')
          ? url
          : this.baseUrl + url,
      data,
      config,
    })
  }
  /*
   * GET API service request
   * @param url
   * @param config
   * @returns {*}
   */
  get = (url, config = {}) => {
    return this.request('GET', url, {}, config)
  }
  /*
   * POST API service request
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  post = (url, data, config = {}) => {
    return this.request('POST', url, data, config)
  }
  /*
   * PUT API service request
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  put = (url, data, config = {}) => {
    return this.request('PUT', url, data, config)
  }
  /*
   * PATCH API service request
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  patch = (url, data, config = {}) => {
    return this.request('PATCH', url, data, config)
  }
  /*
   * DELETE API service request
   * @param url
   * @param config
   * @returns {*}
   */
  delete = (url, config = {}) => {
    return this.request('DELETE', url, {}, config)
  }
}

export default new ApiService()
