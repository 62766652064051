/*
 * @File state.js
 * @Description This File is for app common state management
 */

import Constants from '@/libs/helper/constants'
import { getStrLocal } from '@/libs/helper/local'

const state = {
  loaders: 0,
  toasters: [],
  profile: {},
  appConstants: Constants,
  notify: false,
  notifications: [],
  fcmToken: getStrLocal('fcm'),
}

export default state
