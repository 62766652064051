/*
 * @File actions.js
 * @Description This File is for menu category state actions
 */
import Helpers from '@/libs/helper/functions'
import MenuCategoriesService from '@/libs/services/modules/menu-categories.service'

const actions = {
  /*
   * Menu category list store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    const res = await MenuCategoriesService.list(payload?.params)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuCategoriesList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Menu category create store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async create(context, payload) {
    const res = await MenuCategoriesService.create(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Menu Category details store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async details(context, payload) {
    const res = await MenuCategoriesService.details(payload.id)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuCategoryDetails', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Menu category delete store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async delete(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MenuCategoriesService.destroy(payload.id)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Menu category update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    const res = await MenuCategoriesService.update(payload.formData, payload.id)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default actions
