/*
 * @File getters.js
 * @Description This File is for app common state getters
 */

const getters = {
  /*
   * Getter for loader state
   * @param state
   * @returns {boolean}
   */
  isLoading(state) {
    return state.loaders > 0
  },
  toasts(state) {
    return state.toasters
  },
  profile(state) {
    return state.profile
  },
  getAppConstants(state) {
    return state.appConstants
  },
  getNotify: (state) => {
    return state.notify
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getFcmToken: (state) => {
    return state.fcmToken
  },
}

export default getters
