/*
 * @File state.js
 * @Description This File is for masters state management
 */

const state = {
  masters: {},
  master: {},
}

export default state
