<template>
  <metainfo></metainfo>
  <AppToaster />
  <CLoader />
  <AppDeleteConfirmation />
  <AppRouterView />
</template>
<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
import CLoader from '@/components/CLoader.vue'
import AppToaster from '@/components/AppToaster.vue'
import EventBus from '@/libs/event-bus'
import AppDeleteConfirmation from '@/components/AppDeleteConfirmation.vue'

export default {
  components: { AppDeleteConfirmation, AppToaster, CLoader },
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
    EventBus.$off('force-logout')
    EventBus.$on('force-logout', () => {
      store.commit('user/forceLogout')
      window.location.reload()
    })
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
