<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" status="success" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        {{ userName }}
      </CDropdownHeader>
      <CDropdownItem class="cursor-pointer" @click="$router.push('/profile')">
        <CIcon icon="cil-user" />
        Profile
      </CDropdownItem>
      <!--      <CDropdownItem class="cursor-pointer">-->
      <!--        <CIcon icon="cil-settings" />-->
      <!--        Settings-->
      <!--      </CDropdownItem>-->
      <CDropdownItem class="cursor-pointer" @click="logoutAttempt">
        <CIcon icon="cil-lock-locked" />
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script setup>
import avatar from '@/assets/images/avatars/0.png'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const store = useStore()
const router = useRouter()

const userName = computed(() => store.getters['user/getUserDetails']?.name)

function logoutAttempt() {
  store.commit('app/setLoader')
  store
    .dispatch('user/logout')
    .then(() => {
      router.push({ name: 'Login' })
    })
    .catch(() => {
      store.commit('app/hideLoader')
    })
}
</script>
