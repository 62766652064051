/*
 |--------------------------------------------------------------------------
 | File Name : api-interceptor
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for api-services interceptor settings & config handling
 |
 */

import Helper from '@/libs/helper/functions'
import axios from 'axios'

class ApiInterceptor {
  constructor(service) {
    this.service = service
    this.sourceRequest = {}
  }

  /*
   * Setting common request configurations for axios
   * @param config
   * @returns {*}
   */
  setRequestConfig = (config) => {
    config = {
      ...config,
      ...config.config,
      // eslint-disable-next-line no-prototype-builtins
      canCancel: config.config.params?.hasOwnProperty('canCancel')
        ? config.config.params.canCancel
        : true,
    } // merge configs
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // config.headers['Content-Type'] = 'multipart/form-data'
    //  get access token from local storage for JWT
    const token = Helper.getLocal('token')

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    if (this.sourceRequest[config.url] && config.canCancel) {
      this.sourceRequest[config.url].cancel('Automatic cancellation')
    }

    // Store or update application token
    const axiosSource = axios.CancelToken.source()
    this.sourceRequest[config.url] = { cancel: axiosSource.cancel }
    config.cancelToken = axiosSource.token
    return config
  }
  /*
   * Interceptor handler for success response
   * @param response
   * @returns {{headers, code: *, data}}
   */
  handleSuccessResponse = (response) => {
    return {
      code: response.data.code || response.status,
      headers: response.headers,
      data: response.data,
    }
  }
  /*
   * Interceptor handler for error response
   * @param error
   * @returns {{headers, code, data: T}}
   */
  handleErrorResponse = (error) => {
    return error.response
      ? {
          code: error.response?.status,
          headers: error.response?.headers,
          data: error.response?.data,
        }
      : error
  }
}

export default ApiInterceptor
