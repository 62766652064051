/*
 * @File analytics.service.js
 * @Description This File is for Analytics API services
 */
import ApiServices from '@/libs/services/api-services'

class AnalyticsService {
  constructor() {
    this.request = ApiServices
  }

  getUsersCount = ({ start_date, end_date }) => {
    const params = { start_date, end_date }
    return this.request.get(`user-count`, { params })
  }
  getVendorCount = ({ start_date, end_date }) => {
    const params = { start_date, end_date }
    return this.request.get(`vendor-count`, { params })
  }
  getFoodieCount = ({ start_date, end_date }) => {
    const params = { start_date, end_date }
    return this.request.get(`foodie-count`, { params })
  }
  getMenuCount = ({ start_date, end_date, vendor_id }) => {
    const params = { start_date, end_date, vendor_id }
    return this.request.get(`menu-count`, { params })
  }
  getMenuCategoryCount = ({ start_date, end_date }) => {
    const params = { start_date, end_date }
    return this.request.get(`menu-category-count`, { params })
  }
}

export default new AnalyticsService()
