/*
 |--------------------------------------------------------------------------
 | File Name : menu-categories.service
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for menu categories API services handler
 |
 */
import ApiServices from '@/libs/services/api-services'

class MenuCategoriesService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * Menu Categories list API service
   * @param formData
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('menu-category', { params: params })
  }

  /*
   * Menu Categories details API service
   * @param formData
   * @returns {*}
   */
  details = (id) => {
    return this.request.get(`menu-category/${id}`)
  }
  /*
   * Menu Categories create API service
   * @returns {*}
   */
  create = (formData) => {
    return this.request.post('menu-category', formData)
  }
  /*
   * Menu Categories edit API service
   * @returns {*}
   */
  update = (formData, id) => {
    return this.request.patch(`menu-category/${id}`, formData)
  }
  /*
   * Menu Categories delete API service
   * @returns {*}
   */
  destroy = (id) => {
    return this.request.delete(`menu-category/${id}`)
  }
}

export default new MenuCategoriesService()
