/*
 * @File actions.js
 * @Description This File is for user state actions
 */
import AuthService from '@/libs/services/modules/auth.service'
import Helpers from '@/libs/helper/functions'
import Constants from '@/libs/helper/constants'
import { nextTick } from 'vue'
import { getStrLocal } from '@/libs/helper/local'

const actions = {
  /*
   * User login store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async login(context, payload) {
    const res = await AuthService.login({
      ...payload.formData,
      ...{ device_name: 'web' },
    })
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        if (result.data.user.role === Constants.USER_ROLES.SUPERADMIN) {
          context.commit('setAuthenticated', {
            token: result.data.token,
            userDetails: result.data.user,
          })
        } else {
          context.commit('forceLogout')
        }
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * User logout store action
   * @param context
   * @returns {Promise<unknown>}
   */
  async logout(context) {
    const data = {
      device_token: getStrLocal('fcm'),
    }
    const res = await AuthService.logout(data)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        context.commit('forceLogout')
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * User forgot password store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async forgotPassword(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await AuthService.forgotPassword(payload.formData)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * User reset password from email store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async resetPassword(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await AuthService.resetPassword(payload.formData, payload.url)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Auth user password reset store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async authUserResetPassword(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await AuthService.authUserResetPassword(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * User details update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async updateProfile(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await AuthService.updateProfile(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        if (payload.formData.name) {
          context.commit('setUserDetailsByKey', {
            key: 'name',
            value: payload.formData.name,
          })
        }
        if (payload.formData.phone_number) {
          context.commit('setUserDetailsByKey', {
            key: 'phone_number',
            value: payload.formData.phone_number,
          })
        }
        nextTick().then(() => {
          context.commit('setUserDetails', {
            userDetails: context.getters.getUserDetails,
          })
        })
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
}

export default actions
