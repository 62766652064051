/*
 * @File state.js
 * @Description This File is for menu categories state management
 */

const state = {
  menuCategoriesList: {},
  menuCategoryDetails: {},
}

export default state
