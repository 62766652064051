<template>
  <div v-if="isLoading">
    <div class="c-loader-overlay"></div>
    <CSpinner class="top-50 start-50 position-absolute z-loader" />
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const isLoading = computed(() => store.getters['app/isLoading'])
</script>
