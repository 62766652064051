/*
 * @File state.js
 * @Description This File is for user state management
 */
import Helpers from '@/libs/helper/functions'

const state = {
  token: Helpers.getLocal('token') || null,
  userDetails: JSON.parse(Helpers.getLocal('userDetails')) || {},
}

export default state
