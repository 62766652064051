/*
 * @File mutations.js
 * @Description This File is for vendors state mutations
 */

const mutations = {
  setVendorsList(state, payload) {
    state.vendors = payload
  },
  setVendorDetails(state, payload) {
    state.vendor = payload
  },
  setVendorPhotos(state, payload) {
    state.vendor.photos = state.vendor.photos.map((photo) => {
      return {
        ...photo,
        is_visible:
          payload.id === photo.id ? payload.is_visible : photo.is_visible,
      }
    })
  },
  setMenuList(state, payload) {
    state.menus = payload
  },
  setMenuDetails(state, payload) {
    state.menu = payload
  },
  removePhoto(state, fileId) {
    state.vendor.photos.splice(
      state.vendor.photos.indexOf(
        state.vendor.photos.find((p) => p.id === fileId),
      ),
      1,
    )
  },
  setFilterActiveStatus(state) {
    state.vendorFilterActive = !state.vendorFilterActive
  },
  setFilterWithSortParams(state, payload) {
    state.filterWithSortParams = { ...state.filterWithSortParams, ...payload }
  },
  resetFilterWithSortParams(state) {
    state.filterWithSortParams = {}
  },
}

export default mutations
