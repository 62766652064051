/*
 * @File constants.js
 * @Description This File is for all application constants handling
 */
export const NOTIFICATION_TYPES = {
  VENDOR_REVIEW_ADDED: 1,
  MENU_REVIEW_ADDED: 2,
  VENDOR_LIKED: 3,
  MENU_LIKED: 4,
  VENDOR_UNLIKED: 5,
  MENU_UNLIKED: 6,
  FOODIE_FOLLOWED: 7,
  FOODIE_UNFOLLOWED: 8,
  VENDOR_SIGNUP: 9,
  FOODIE_SIGNUP: 10,
}

export default {
  ATTRIBUTE_TYPE: {
    VENDOR: 1,
    MENU: 2,
  },
  USER_ROLES: {
    SUPERADMIN: 0,
  },
  SORT_TYPES: {
    asc: 'asc',
    desc: 'desc',
  },
  REFRESH_LIST_EVENTS: {
    VENDOR: 'vendor-list-refresh',
    FOODIE: 'foodie-list-refresh',
    ATTRIBUTE: 'attribute-list-refresh',
    POST: 'post-list-refresh',
  },
  TAGGABLE_TYPES: {
    VENDOR: 1,
    MENU: 2,
  },
  NOTIFICATION_TYPES: NOTIFICATION_TYPES,
  POST_TYPES: {
    IMAGE: 1,
    VIDEO: 2,
  },
}
