/*
 * @File actions.js
 * @Description This File is for foodies state actions
 */

import Helpers from '@/libs/helper/functions'
import FoodiesService from '@/libs/services/modules/foodies.service'

const actions = {
  /*
   * foodies List store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    const res = await FoodiesService.list({
      ...payload,
      ...context.getters['getFilterWithSortParams'],
    })
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setFoodieList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Update foodies store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    const res = await FoodiesService.update(payload.id, payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Foodie details fetching store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async details(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await FoodiesService.show(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setFoodieDetails', result.data)
        context.commit('app/hideLoader', true, { root: true })
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Delete foodies store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async delete(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await FoodiesService.destroy(payload.id)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Foodies like(s) list store action
   * @param context
   * @param params
   * @returns {Promise<unknown>}
   */
  async likesList(context, params) {
    context.commit('app/setLoader', true, { root: true })
    const res = await FoodiesService.likes(params)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setFoodieLikes', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
}

export default actions
