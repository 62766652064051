// Local storage Utils

// Store data in localstorage
export const setLocal = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}

// Get data from localstorage
export const getLocal = (key) => {
  const data = localStorage.getItem(key)
  if (data === 'undefined') return null
  return JSON.parse(data)
}

// Remove data from localstorage
export const removeLocal = (key) => {
  return localStorage.removeItem(key)
}

// Check for data in localstorage
export const checkLocal = (key) => {
  return !!localStorage.getItem(key)
}

// Get string data from localstorage
export const getStrLocal = (key) => {
  return localStorage.getItem(key)
}
