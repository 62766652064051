/*
 * @File index.js
 * @Description This File is for masters store module
 */
import state from '@/store/modules/masters/state'
import mutations from '@/store/modules/masters/mutations'
import actions from '@/store/modules/masters/actions'
import getters from '@/store/modules/masters/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
