/*
 |--------------------------------------------------------------------------
 | File Name : functions
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for common helper functions
 |
 */
import axios from 'axios'
import EventBus from '../event-bus'
import dayjs from 'dayjs'

export default {
  /*
   * Common response analysis
   * @param response
   * @param showToast
   * @param schema
   * @returns {Promise<never>|Promise<unknown>}
   */
  checkResponse(response, showToast = true, schema = {}) {
    /*
     | ==================================
     | Handler for response check by code
     | ==================================
     */
    if (response) {
      switch (response.code) {
        /*
         | ================
         | Success response
         | ================
         */
        case 200:
        case 201:
          if (showToast) {
            this.successToaster(response.data.message || 'Successfully updated')
          }
          return Promise.resolve(response.data)
        case 401:
          this.errorToaster(response.data.message)
          EventBus.$emit('force-logout')
          return Promise.reject(response.data)
        /*
         | =========================
         | Validation error response
         | =========================
         */
        case 422:
          if (response.data.errors) {
            this.errorToaster(response.data.message)
            Object.entries(response.data.errors).forEach((entry) => {
              if (schema && !schema[entry[0]]) {
                this.errorToaster(entry[1][0])
              } else {
                schema[entry[0]].error = entry[1][0]
              }
            })
            return Promise.reject(response.data.errors)
          }
          this.errorToaster(response.data.message || 'Given data is invalid')
          return Promise.reject(response.data)
        default:
          if (!axios.isCancel(response)) {
            this.errorToaster(
              response.data && response.data.message
                ? response.data.message
                : 'The Request Failed! Please try again.',
            )
          }
          return Promise.reject(response || {})
      }
    } else {
      if (!axios.isCancel(response)) {
        this.errorToaster('Something went wrong')
      }
      return Promise.reject(response || {})
    }
  },
  /*
   * Global toaster handler
   * @param message
   * @param type
   * @returns {ActiveToast}
   */
  toaster(message, type) {
    EventBus.$emit('trigger-toast', { message, type })
  },
  /*
   * Success toaster handler
   * @param message
   */
  successToaster(message = 'Successfully completed.') {
    this.toaster(message, 'success')
  },
  /*
   * Error toaster handler
   * @param message
   */
  errorToaster(message = 'Something went wrong!') {
    this.toaster(message, 'error')
  },
  /*
   * Object parser
   * @param proxyObject
   * @returns {any}
   */
  renderObject(proxyObject) {
    return JSON.parse(JSON.stringify(proxyObject))
  },
  /*
   * Updates local storage
   * @param key
   * @param value
   */
  setLocal(key, value) {
    localStorage.setItem(key, value)
  },
  /*
   * Access local storage
   * @param key
   */
  getLocal(key) {
    return localStorage.getItem(key)
  },
  /*
   * Remove local storage
   * @param key
   */
  removeLocal(key) {
    localStorage.removeItem(key)
  },
  /*
   * Returns week day name w.r.t index
   * @param index
   * @returns {string}
   */
  getWeekDayByIndex(index = 0) {
    return [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][index]
  },
  /*
   * Date formatting with dayjs
   * @param dateString
   * @param format
   * @returns {string}
   */
  formatDate(dateString, format = 'DD MMM YYYY') {
    const date = dayjs(dateString)
    // Then specify how you want your dates to be formatted
    return date.format(format)
  },
}
