/*
 * @File vendors.service.js
 * @Description This File is for app API services
 */
import ApiServices from '@/libs/services/api-services'

class AppService {
  constructor() {
    this.request = ApiServices
  }

  getNotifications = ({ per_page, page, type }) => {
    const params = { per_page, page, type, noLoading: true }
    return this.request.get(`superadmin-notifications`, { params })
  }
}

export default new AppService()
