/*
 * @File reviews.service.js
 * @Description This File is for review(s) API services
 */
import ApiServices from '@/libs/services/api-services'
import Constants from '@/libs/helper/constants'

class ReviewService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List reviews API service
   * @param params
   * @returns {*}
   */
  list(params = { taggable_type: Constants.TAGGABLE_TYPES.VENDOR }) {
    return this.request.get('review', { params })
  }

  /*
   * Delete review API service
   * @param reviewId
   * @returns {*}
   */
  delete(reviewId) {
    return this.request.delete(`review/${reviewId}`)
  }
}

export default new ReviewService()
