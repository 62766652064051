/*
 * @File file-uploader.service.js
 * @Description This File is for
 */
import ApiServices from '@/libs/services/api-services'

class FileUploaderService {
  constructor() {
    this.request = ApiServices
  }

  uploadFiles(fileArray) {
    return this.request.post('file', fileArray)
  }
}

export default new FileUploaderService()
