/*
 * @File actions.js
 * @Description This File is for vendors state actions
 */

import Helpers from '@/libs/helper/functions'
import VendorsService from '@/libs/services/modules/vendors.service'
import MenuService from '@/libs/services/modules/menu.service'

const actions = {
  /*
   * vendors List store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    const res = await VendorsService.list({
      ...payload,
      ...context.getters['getFilterWithSortParams'],
    })
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setVendorsList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Create vendors store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async create(context, payload) {
    const res = await VendorsService.create(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Update vendors store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    const res = await VendorsService.update(payload.id, payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Vendor details fetching store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async details(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await VendorsService.show(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setVendorDetails', result.data)
        context.commit('app/hideLoader', true, { root: true })
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Vendor images upload store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async uploadImages(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await VendorsService.upload(payload.id, payload.formData)
    return Helpers.checkResponse(res, true)
      .then(async (result) => {
        await context.dispatch('details', payload.id)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * vendor gallery images update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async updateImages(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await VendorsService.updateImage(payload.id, payload.formData)
    return Helpers.checkResponse(res, true)
      .then(async (result) => {
        if (payload.formData.photos[0]) {
          context.commit('setVendorPhotos', payload.formData.photos[0])
        }
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Delete gallery images store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async deleteImages(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await VendorsService.deleteImage(payload.id, payload.formData)
    return Helpers.checkResponse(res, true)
      .then(async (result) => {
        if (payload.formData.photos[0]) {
          context.commit('removePhoto', payload.formData.photos[0])
        }
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Delete vendors store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async delete(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await VendorsService.destroy(payload.id)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Create vendor Menu store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async createMenu(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MenuService.create(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * update vendor Menu store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async updateMenu(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MenuService.update(payload.id, payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * List vendor menu store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async listMenu(context, payload) {
    const res = await MenuService.list(payload?.params)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Delete menu store action
   * @param context
   * @param menuId
   * @returns {Promise<unknown>}
   */
  async deleteMenu(context, menuId) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MenuService.delete(menuId)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * fetching menu details
   * @param context
   * @param menuId
   * @returns {Promise<unknown>}
   */
  async menuDetails(context, menuId) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MenuService.show(menuId)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuDetails', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
}

export default actions
