/*
 * @File mutations.js
 * @Description This File is for app common state mutations
 */

const mutations = {
  setLoader(state) {
    if (state.loaders < 0) {
      state.loaders = 1
    } else {
      state.loaders++
    }
  },
  hideLoader(state) {
    if (state.loaders <= 0) {
      state.loaders = 0
    } else {
      state.loaders--
    }
  },
  resetLoaders(state) {
    state.loaders = 0
  },
  addToast(state, payload) {
    state.toasters.push(payload)
  },
  removeToast(state, payload) {
    state.toasters.splice(state.toasters.indexOf(payload), 1)
  },
  setProfile(state, payload) {
    state.profile = payload
  },

  setNotify: (state, payload) => {
    state.notify = payload
  },

  setNotifications: (state, payload) => {
    state.notifications = payload
  },

  setFcmToken: (state, payload) => {
    state.fcmToken = payload
  },
}

export default mutations
