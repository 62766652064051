/*
 |--------------------------------------------------------------------------
 | File Name : guards
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for access guards
 |
 */
import Helper from './functions'
const NON_AUTH_ROUTES = ['Forgot Password', 'Reset Password']
export default {
  /*
   * Authentication guard
   * @param to
   * @param from
   * @param next
   */
  authGuard(to, from, next) {
    const isLoggedIn = !!Helper.getLocal('token')
    if (to.name !== 'Login') {
      if (isLoggedIn) {
        next()
      } else if (!NON_AUTH_ROUTES.includes(to.name)) {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      if (isLoggedIn) {
        next({ name: 'Home' })
      } else {
        next()
      }
    }
  },
  /*
   * Guard after page loaded
   * @param to
   * @param from
   * @param next
   * @param store
   */
  postPageLoadGuard(to, from, next, store) {
    if ([from.name, to.name].includes('Login')) {
      store.commit('app/resetLoaders')
    }
  },
}
