/*
 * @File actions.js
 * @Description This File is for analytics state actions
 */

import Helpers from '@/libs/helper/functions'
import AnalyticsService from '@/libs/services/modules/analytics.service'

const actions = {
  /*
   * Users count store action
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async getUsersCount(context, payload) {
    const res = await AnalyticsService.getUsersCount(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setUsersCount', result.data?.count)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Vendors count store action
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async getVendorCount(context, payload) {
    const res = await AnalyticsService.getVendorCount(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setVendorCount', result.data?.count)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Foodie count store action
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async getFoodieCount(context, payload) {
    const res = await AnalyticsService.getFoodieCount(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setFoodieCount', result.data?.count)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Menu count store action
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async getMenuCount(context, payload) {
    const res = await AnalyticsService.getMenuCount(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuCount', result.data?.count)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Menu Category count store action
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async getMenuCategoryCount(context, payload) {
    const res = await AnalyticsService.getMenuCategoryCount(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setMenuCategoryCount', result.data?.count)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default actions
