/*
 * @File getters.js
 * @Description This File is for menu category state getters
 */

const getters = {
  /*
   * Getter for menu categories dataset
   * @param state
   * @returns {boolean}
   */
  getMenuCategoriesList(state) {
    return state.menuCategoriesList
  },
  /*
   * Getter for menu category dataset
   * @param state
   * @returns {boolean}
   */
  getMenuCategoryDetails(state) {
    return state.menuCategoryDetails
  },
}

export default getters
