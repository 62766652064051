/*
 * @File mutations.js
 * @Description This File is for attributes state mutations
 */

const mutations = {
  setAttributesList(state, payload) {
    state.attributesList = payload
  },
  setAttributeDetails(state, payload) {
    state.attribute = payload
  },
  setFilterActiveStatus(state) {
    state.filterActive = !state.filterActive
  },
  setFilterWithSortParams(state, payload) {
    state.filterWithSortParams = { ...state.filterWithSortParams, ...payload }
  },
  resetFilterWithSortParams(state) {
    state.filterWithSortParams = {}
  },
}

export default mutations
