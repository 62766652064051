/*
 * @File index.js
 * @Description This File is for analytics store module
 */
import state from '@/store/modules/analytics/state'
import mutations from '@/store/modules/analytics/mutations'
import actions from '@/store/modules/analytics/actions'
import getters from '@/store/modules/analytics/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
