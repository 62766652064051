/*
 * @File getters.js
 * @Description This File is for user state getters
 */

const getters = {
  /*
   * Getter for auth state
   * @param state
   * @returns {boolean}
   */
  isLoggedIn(state) {
    return !!state.token && !!state.userDetails.id
  },
  /*
   * Getter for auth user details
   * @param state
   * @returns {*}
   */
  getUserDetails(state) {
    return state.userDetails
  },
}

export default getters
