/*
 * @File mutations.js
 * @Description This File is for user state mutations
 */

import Helpers from '@/libs/helper/functions'

const mutations = {
  setAuthenticated(state, payload) {
    Helpers.setLocal('token', payload.token)
    Helpers.setLocal('userDetails', JSON.stringify(payload.userDetails))
    state.token = payload.token
    state.userDetails = payload.userDetails
  },
  forceLogout(state) {
    Helpers.removeLocal('token')
    Helpers.removeLocal('userDetails')
    state.token = null
    state.userDetails = {}
  },
  setUserDetails(state, payload) {
    Helpers.setLocal('userDetails', JSON.stringify(payload.userDetails))
    state.userDetails = payload.userDetails
  },
  setUserDetailsByKey(state, { key, value }) {
    state.userDetails[key] = value
  },
}

export default mutations
