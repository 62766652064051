/*
 * @File masters.service.js
 * @Description This File is for masters API services
 */
import ApiServices from '@/libs/services/api-services'

class MastersService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List masters API service
   * @param params
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('master', { params })
  }
  /*
   * Update masters API service
   * @param formData
   * @returns {*}
   */
  update = (formData) => {
    return this.request.patch(`master`, formData)
  }
}

export default new MastersService()
