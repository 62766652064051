/*
 * @File actions.js
 * @Description This File is for masters state actions
 */

import Helpers from '@/libs/helper/functions'
import MastersService from '@/libs/services/modules/masters.service'

const actions = {
  /*
   * Masters List store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MastersService.list(payload)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit(
          payload?.key ? 'setMasterDetails' : 'setMastersList',
          result.data,
        )
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Update masters store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    context.commit('app/setLoader', true, { root: true })
    const res = await MastersService.update(payload.formData)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
}

export default actions
