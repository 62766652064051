/*
 * @File getters.js
 * @Description This File is for post state getters
 */

import constants from '@/libs/helper/constants'

const getters = {
  /*
   * Getter for post dataset
   * @param state
   * @returns {boolean}
   */
  getPostsList(state) {
    return state.postsList?.data?.map((attr) => {
      return {
        ...attr,
        typeLabel: postTypeLabel(attr),
      }
    })
  },
  /*
   * Accessor for post list paginator
   * @param state
   * @returns {{}}
   */
  getPostsListPaginator(state) {
    return state.postsList
  },
  /*
   * Getter for post dataset
   * @param state
   * @returns {boolean}
   */
  getPostDetails(state) {
    return state.post
  },
}

/*
 * Post type label rendering
 * @param attr
 * @returns {string}
 */
function postTypeLabel(attr) {
  switch (attr.type) {
    case constants.POST_TYPES.IMAGE:
      return 'Image'
    case constants.POST_TYPES.VIDEO:
      return 'Video'
    default:
      return 'Unknown'
  }
}

export default getters
