import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging'

import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
const cloudMessagingKey = process.env.VUE_APP_CLOUD_MESSAGING_KEY

const messaging = getMessaging(app)
const analytics = getAnalytics(app)

export {
  messaging,
  getToken,
  onMessage,
  cloudMessagingKey,
  deleteToken,
  analytics,
  logEvent,
}
