/*
 * @File getters.js
 * @Description This File is for attributes state getters
 */

import constants from '@/libs/helper/constants'

const getters = {
  /*
   * Getter for attributes dataset
   * @param state
   * @returns {boolean}
   */
  getAttributesList(state) {
    return state.attributesList?.data?.map((attr) => {
      return {
        ...attr,
        typeLabel: attributeTypeLabel(attr),
      }
    })
  },
  /*
   * Accessor for attributes list paginator
   * @param state
   * @returns {{}}
   */
  getAttributesListPaginator(state) {
    return state.attributesList
  },
  /*
   * Getter for attribute dataset
   * @param state
   * @returns {boolean}
   */
  getAttributeDetails(state) {
    return state.attribute
  },
  getFilterActiveStatus(state) {
    return state.filterActive
  },
  getFilterWithSortParams(state) {
    return state.filterWithSortParams
  },
}

/*
 * Attributes type label rendering
 * @param attr
 * @returns {string}
 */
function attributeTypeLabel(attr) {
  switch (attr.type) {
    case constants.ATTRIBUTE_TYPE.VENDOR:
      return 'Vendor'
    case constants.ATTRIBUTE_TYPE.MENU:
      return 'Menu'
    default:
      return 'Unknown'
  }
}

export default getters
