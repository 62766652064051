/*
 * @File getters.js
 * @Description This File is for masters state getters
 */

const getters = {
  /*
   * Accessor for masters
   * @param state
   * @returns {{}}
   */
  getMasters(state) {
    return state.masters
  },
  /*
   * Accessor for master detail
   * @param state
   * @returns {{}}
   */
  getMaster(state) {
    return state.master
  },
}

export default getters
