/*
 * @File menu.service.js
 * @Description This File is for menu API services handler
 */

import ApiServices from '@/libs/services/api-services'

class MenuService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List Menu API service
   * @param formData
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('menu', { params })
  }
  /*
   * Create Menu API service
   * @param formData
   * @returns {*}
   */
  create = (formData) => {
    return this.request.post('menu', formData)
  }
  /*
   * Update Menu API service
   * @param formData
   * @returns {*}
   */
  update = (menuId, formData) => {
    return this.request.patch(`menu/${menuId}`, formData)
  }
  /*
   * Delete menu API service
   * @param menuId
   * @returns {*}
   */
  delete = (menuId) => {
    return this.request.delete(`menu/${menuId}`)
  }
  /*
   * Show Menu details API service
   * @param menuId
   * @returns {*}
   */
  show = (menuId) => {
    return this.request.get(`menu/${menuId}`)
  }
}

export default new MenuService()
