/*
 * @File index.js
 * @Description This File is for posts store module
 */
import state from '@/store/modules/posts/state'
import mutations from '@/store/modules/posts/mutations'
import actions from '@/store/modules/posts/actions'
import getters from '@/store/modules/posts/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
