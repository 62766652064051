/*
 * @File mutations.js
 * @Description This File is for menu categories state mutations
 */

const mutations = {
  setMenuCategoriesList(state, payload) {
    state.menuCategoriesList = payload
  },
  setMenuCategoryDetails(state, payload) {
    state.menuCategoryDetails = payload
  },
}

export default mutations
