/*
 * @File state.js
 * @Description This File is for posts state management
 */

const state = {
  postsList: {},
  post: {},
}

export default state
