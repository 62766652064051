<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>
<script>
export default {
  name: 'AppRouterView',
}
</script>
<script setup></script>
<style scoped lang="scss"></style>
