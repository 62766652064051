/*
 * @File vendors.service.js
 * @Description This File is for vendors API services
 */
import ApiServices from '@/libs/services/api-services'

class VendorsService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List vendors API service
   * @param params
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('vendor', { params })
  }
  /*
   * Vendor details API service
   * @param id
   * @returns {*}
   */
  show = (id) => {
    return this.request.get(`vendor/${id}`)
  }
  /*
   * Create vendors API service
   * @param formData
   * @returns {*}
   */
  create = (formData) => {
    return this.request.post('vendor', formData)
  }
  /*
   * Update vendors details API service
   * @param id
   * @param formData
   * @returns {*}
   */
  update = (id, formData) => {
    return this.request.patch(`vendor/${id}`, formData)
  }
  /*
   * Delete vendors API service
   * @param id
   * @returns {*}
   */
  destroy = (id) => {
    return this.request.delete(`vendor/${id}`)
  }
  /*
   * File uploader API service
   * @param id
   * @param formData
   * @returns {*}
   */
  upload = (id, formData) => {
    return this.request.post(`vendor/${id}/photos`, formData)
  }
  /*
   * File update API service
   * @param id
   * @param formData
   * @returns {*}
   */
  updateImage = (id, formData) => {
    return this.request.patch(`vendor/${id}/photos/visible`, formData)
  }
  /*
   * File remove API service
   * @param id
   * @param formData
   * @returns {*}
   */
  deleteImage = (id, formData) => {
    return this.request.patch(`vendor/${id}/photos`, formData)
  }
}

export default new VendorsService()
