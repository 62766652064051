/*
 * @File getters.js
 * @Description This File is for foodies state getters
 */

const getters = {
  /*
   * Getter for foodies dataset
   * @param state
   * @returns {boolean}
   */
  getFoodiesList(state) {
    return state.foodies?.data || []
  },
  /*
   * Foodies list with pagination
   * @param state
   * @returns {{}}
   */
  getFoodiesListPaginator(state) {
    return state.foodies
  },
  /*
   * Getter for foodie details
   * @param state
   * @returns {boolean}
   */
  getFoodieDetails(state) {
    return state.foodie
  },
  getFilterActiveStatus(state) {
    return state.foodieFilterActive
  },
  getFilterWithSortParams(state) {
    return state.filterWithSortParams
  },
  getFoodiesLikesList(state) {
    return state.likes.data || []
  },
  getFoodiesLikesPagination(state) {
    return state.likes || {}
  },
}

export default getters
