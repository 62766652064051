/*
 * @File mutations.js
 * @Description This File is for masters state mutations
 */

const mutations = {
  /*
   * Mutator for masters list
   * @param state
   * @param payload
   */
  setMastersList(state, payload) {
    state.masters = payload
  },
  /*
   * Mutator for master details
   * @param state
   * @param payload
   */
  setMasterDetails(state, payload) {
    state.master = payload
  },
}

export default mutations
