<template>
  <c-toaster placement="bottom-start" visible>
    <c-toast
      color="dark"
      class="text-white align-items-center"
      visible
      :delay="3000"
      v-for="toast in toasts"
      :key="toast"
      @close="closeHandler(toast)"
    >
      <div class="d-flex">
        <c-toast-body>{{ toast }}</c-toast-body>
        <CToastClose class="me-2 m-auto" white />
      </div>
    </c-toast>
  </c-toaster>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import EventBus from '@/libs/event-bus'

const store = useStore()

const toasts = computed(() => store.getters['app/toasts'])

function closeHandler(toast) {
  store.commit('app/removeToast', toast)
}

onMounted(() => {
  EventBus.$off('trigger-toast')
  EventBus.$on('trigger-toast', (payload) => {
    if (payload.message) {
      store.commit('app/addToast', payload.message)
    }
  })
})
</script>
