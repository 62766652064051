/*
 * @File actions.js
 * @Description This File is for post state actions
 */
import Helpers from '@/libs/helper/functions'
import PostsService from '@/libs/services/modules/posts.service'

const actions = {
  /*
   * Posts list store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async list(context, payload) {
    const res = await PostsService.list({ ...payload })
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setPostsList', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Post details store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async details(context, payload) {
    const res = await PostsService.details(payload.id)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setPostDetails', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Post create store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async create(context, payload) {
    const res = await PostsService.create(
      payload.formData,
      payload.params || {},
    )
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Post delete store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async deleteItem(context, payload) {
    const res = await PostsService.destroy(payload.id)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  /*
   * Post update store action
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  async update(context, payload) {
    const res = await PostsService.update(payload.formData, payload.id)
    return Helpers.checkResponse(res, true, payload.schema)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}

export default actions
