/*
 |--------------------------------------------------------------------------
 | File Name : index
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for global helper handling
 |
 */
import functions from '@/libs/helper/functions'
import guards from '@/libs/helper/guards'
import constants from '@/libs/helper/constants'

export default {
  /*
   * Vue js custom plugin config
   * @param app
   * @param options
   */
  install: (app) => {
    /*
     * Custom helper functions
     */
    app.config.globalProperties.$helper = functions
    app.config.globalProperties.$constants = constants
    app.config.globalProperties.$guards = guards
    app.provide('helper', functions)
    app.provide('constants', constants)
  },
}
