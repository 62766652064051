import { createStore } from 'vuex'
import user from '@/store/modules/user'
import app from '@/store/modules/app'
import attributes from '@/store/modules/attributes'
import vendors from '@/store/modules/vendors'
import foodies from '@/store/modules/foodies'
import menuCategories from '@/store/modules/menu-categories'
import masters from '@/store/modules/masters'
import reviews from '@/store/modules/reviews'
import analytics from '@/store/modules/analytics'
import posts from '@/store/modules/posts'
export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'light',
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    user,
    app,
    attributes,
    vendors,
    foodies,
    menuCategories,
    masters,
    reviews,
    analytics,
    posts,
  },
})
