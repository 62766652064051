/*
 * @File mutations.js
 * @Description This File is for reviews state mutations
 */

const mutations = {
  /*
   * Set reviews data
   * @param state
   * @param payload
   */
  setReviews(state, payload) {
    state.reviews = payload
  },
}

export default mutations
