/*
 * @File mutations.js
 * @Description This File is for posts state mutations
 */

const mutations = {
  setPostsList(state, payload) {
    state.postsList = payload
  },
  setPostDetails(state, payload) {
    state.post = payload
  },
}

export default mutations
