<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler
        :class="[
          'px-md-0',
          'me-md-3',
          'd-lg-none',
          { 'prevent-hide': !sidebarVisible },
        ]"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" class="text-white" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none" to="/">
        <h5>{{ appName }}</h5>
      </CHeaderBrand>
      <CForm class="d-none d-md-flex">
        <!--        <CInputGroup>-->
        <!--          <CInputGroupText id="search-addon" class="bg-light border-0 px-1">-->
        <!--            <CIcon-->
        <!--              icon="cil-search"-->
        <!--              size="lg"-->
        <!--              class="my-1 mx-2 text-disabled"-->
        <!--            />-->
        <!--          </CInputGroupText>-->
        <!--          <CFormInput-->
        <!--            placeholder="Search..."-->
        <!--            ariaLabel="Search"-->
        <!--            ariaDescribedby="search-addon"-->
        <!--            class="bg-light border-0"-->
        <!--          />-->
        <!--        </CInputGroup>-->
        <h5>{{ $route.name }}</h5>
      </CForm>
      <CHeaderNav class="d-none d-sm-flex ms-auto me-3">
        <AppHeaderDropdownNotif />
      </CHeaderNav>
      <CHeaderNav class="ms-auto ms-sm-0 me-sm-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    AppHeaderDropdownNotif,
  },
  setup() {
    const store = useStore()
    const appName = computed(() => {
      return process.env.VUE_APP_NAME
    })
    return {
      appName,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
