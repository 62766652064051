export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Vendors',
    to: '/vendors',
    icon: 'cil-bank',
  },
  {
    component: 'CNavItem',
    name: 'Foodies',
    to: '/foodies',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Attributes',
    to: '/attributes',
    icon: 'cil-calculator',
  },
  {
    component: 'CNavItem',
    name: 'Menu Categories',
    to: '/menu-categories',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Masters',
    to: '/masters',
    icon: 'cil-storage',
  },
  {
    component: 'CNavItem',
    name: 'Posts (admin)',
    to: '/posts',
    icon: 'cil-newspaper',
  },
]
