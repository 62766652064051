import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Helper from '@/libs/helper'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import { createMetaManager } from 'vue-meta'
import AppRouterView from '@/components/AppRouterView.vue'
import '@/libs/helper/notifications'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Helper)
app.use(createMetaManager())
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('AppRouterView', AppRouterView)

/*
 * Router guards / middleware
 */
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_NAME} Admin | ${to.name}`
  app.config.globalProperties.$guards.authGuard(to, from, next)
})

router.afterEach((to, from, next) => {
  app.config.globalProperties.$guards.postPageLoadGuard(
    to,
    from,
    next,
    app.config.globalProperties.$store,
  )
})

app.mount('#app')
