/*
 * @File index.js
 * @Description This File is for app common store module
 */
import state from '@/store/modules/app/state'
import mutations from '@/store/modules/app/mutations'
import actions from '@/store/modules/app/actions'
import getters from '@/store/modules/app/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
