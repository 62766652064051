/*
 * @File state.js
 * @Description This File is for foodies state management
 */

const state = {
  foodies: {},
  foodie: {},
  foodieFilterActive: false,
  filterWithSortParams: {},
  likes: {},
}

export default state
