/*
 * @File posts.service.js
 * @Description This File is for posts API services
 */
import ApiServices from '@/libs/services/api-services'

class PostsService {
  constructor() {
    this.request = ApiServices
  }

  /*
   * List  Posts API service
   * @param params
   * @returns {*}
   */
  list = (params) => {
    return this.request.get('post', { params })
  }
  /*
   * Post details API service
   * @param id
   * @returns {*}
   */
  details = (id) => {
    return this.request.get(`post/${id}`)
  }
  /*
   * Post create API service
   * @returns {*}
   */
  create = (formData, params = {}) => {
    return this.request.post('post', formData, { params })
  }
  /*
   * Update Post details API service
   * @param id
   * @param formData
   * @returns {*}
   */
  update = (formData, id) => {
    return this.request.patch(`post/${id}`, formData)
  }
  /*
   * Delete Post API service
   * @param id
   * @returns {*}
   */
  destroy = (id) => {
    return this.request.delete(`post/${id}`)
  }
}

export default new PostsService()
