/*
 * @File getters.js
 * @Description This File is for reviews state getters
 */

const getters = {
  getReviewsList(state) {
    return state.reviews.data || []
  },
  getReviewsPagination(state) {
    return state.reviews
  },
}

export default getters
