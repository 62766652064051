/*
 * @File actions.js
 * @Description This File is for reviews state actions
 */

import Helpers from '@/libs/helper/functions'
import ReviewsService from '@/libs/services/modules/reviews.service'
import Constants from '@/libs/helper/constants'

const actions = {
  /*
   * List reviews store action
   * @param context
   * @param params
   * @returns {Promise<*>}
   */
  async listReviews(
    context,
    params = { taggable_type: Constants.TAGGABLE_TYPES.VENDOR },
  ) {
    context.commit('app/setLoader', true, { root: true })
    const res = await ReviewsService.list(params)
    return Helpers.checkResponse(res, false)
      .then((result) => {
        context.commit('setReviews', result.data)
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
  /*
   * Delete review store action
   * @param context
   * @param reviewId
   * @returns {Promise<unknown>}
   */
  async deleteReview(context, reviewId) {
    context.commit('app/setLoader', true, { root: true })
    const res = await ReviewsService.delete(reviewId)
    return Helpers.checkResponse(res, true)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('app/hideLoader', true, { root: true })
      })
  },
}

export default actions
