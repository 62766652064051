/*
 * @File state.js
 * @Description This File is for vendors state management
 */

const state = {
  vendors: {},
  vendor: {},
  menus: {},
  menu: {},
  vendorFilterActive: false,
  filterWithSortParams: {},
}

export default state
