<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" :caret="false" @click="setNotify">
      <span class="d-inline-block my-1 mx-2 position-relative">
        <CIcon icon="cil-bell" class="text-dark" size="lg" />
        <CBadge
          color="danger"
          position="top-end"
          shape="rounded-circle"
          class="p-1"
          v-if="getNotify"
        >
          <span class="visually-hidden">{{ itemsCount }}</span>
        </CBadge>
      </span>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10"
      >
        <strong>Notifications</strong>
      </CDropdownHeader>
      <CDropdownItem
        v-for="item in notifications"
        :key="item.id"
        class="cursor-pointer"
        @click="navigate(item)"
      >
        <CIcon icon="cil-user-follow" class="text-success" />
        {{ item.title }}
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
export default {
  name: 'TheHeaderDropdownNotif',
}
</script>

<script setup>
import { computed, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { NOTIFICATION_TYPES } from '@/libs/helper/constants'

const itemsCount = ref(1)

const store = useStore()
const router = useRouter()
function fetchNotifications(page = 1, per_page = 4) {
  nextTick().then(() => {
    store.dispatch('app/getNotifications', { page, per_page }).finally(() => {})
  })
}

const notifications = computed(() => store.getters['app/getNotifications'])
const getNotify = computed(() => store.getters['app/getNotify'])

const setNotify = () => {
  fetchNotifications()
  store.commit('app/setNotify', false)
}

/*
 * Notification action
 * @param item
 */
function navigate(item) {
  nextTick().then(async () => {
    switch (item.type) {
      case NOTIFICATION_TYPES.VENDOR_SIGNUP:
        await router.push({
          name: 'Vendor Details',
          params: { id: item.typeId },
          force: true,
        })
        router.go(0)
        break
      case NOTIFICATION_TYPES.FOODIE_SIGNUP:
        await router.push({
          name: 'Foodie Details',
          params: { id: item.typeId },
          force: true,
        })
        router.go(0)
        break
    }
  })
}
</script>
