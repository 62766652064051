/*
 * @File state.js
 * @Description This File is for analytics state management
 */

const state = {
  usersCount: 0,
  vendorCount: 0,
  foodieCount: 0,
  menuCount: 0,
  menuCategoryCount: 0,
}

export default state
