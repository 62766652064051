/*
 * @File mutations.js
 * @Description This File is for foodies state mutations
 */

const mutations = {
  setFoodieList(state, payload) {
    state.foodies = payload
  },
  setFoodieDetails(state, payload) {
    state.foodie = payload
  },
  setFilterActiveStatus(state) {
    state.foodieFilterActive = !state.foodieFilterActive
  },
  setFilterWithSortParams(state, payload) {
    state.filterWithSortParams = { ...state.filterWithSortParams, ...payload }
  },
  resetFilterWithSortParams(state) {
    state.filterWithSortParams = {}
  },
  setFoodieLikes(state, payload) {
    state.likes = payload
  },
}

export default mutations
