/*
 * @File getters.js
 * @Description This File is for analytics state getters
 */

const getters = {
  getUsersCount(state) {
    return state.usersCount
  },
  getVendorCount(state) {
    return state.vendorCount
  },
  getFoodieCount(state) {
    return state.foodieCount
  },
  getMenuCount(state) {
    return state.menuCount
  },
  getMenuCategoryCount: (state) => {
    return state.menuCategoryCount
  },
}

export default getters
