/*
 * @File mutations.js
 * @Description This File is for analytics state mutations
 */

const mutations = {
  setUsersCount(state, payload) {
    state.usersCount = payload
  },
  setVendorCount(state, payload) {
    state.vendorCount = payload
  },
  setFoodieCount(state, payload) {
    state.foodieCount = payload
  },
  setMenuCount: (state, payload) => {
    state.menuCount = payload
  },
  setMenuCategoryCount: (state, payload) => {
    state.menuCategoryCount = payload
  },
}

export default mutations
