/*
 * @File state.js
 * @Description This File is for attributes state management
 */

const state = {
  attributesList: {},
  attribute: {},
  filterActive: false,
  filterWithSortParams: {},
}

export default state
