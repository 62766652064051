/*
 * @File getters.js
 * @Description This File is for vendors state getters
 */

const getters = {
  /*
   * Getter for vendors dataset
   * @param state
   * @returns {boolean}
   */
  getVendorsList(state) {
    return state.vendors?.data || []
  },
  /*
   * Vendors list with pagination
   * @param state
   * @returns {{}}
   */
  getVendorsListPaginator(state) {
    return state.vendors
  },
  /*
   * Getter for vendors details
   * @param state
   * @returns {boolean}
   */
  getVendorDetails(state) {
    return state.vendor
  },
  getVendorMenusList(state) {
    return state.menus?.data || []
  },
  getVendorMenusPaginator(state) {
    return state.menus
  },
  getMenuDetails(state) {
    return state.menu
  },
  getFilterActiveStatus(state) {
    return state.vendorFilterActive
  },
  getFilterWithSortParams(state) {
    return state.filterWithSortParams
  },
}

export default getters
