/*
 * @File index.js
 * @Description This File is for menu categories store module
 */
import state from '@/store/modules/menu-categories/state'
import mutations from '@/store/modules/menu-categories/mutations'
import actions from '@/store/modules/menu-categories/actions'
import getters from '@/store/modules/menu-categories/getters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
